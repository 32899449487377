<template>
  <div>
    <loginDialog ref="dialog"/>
    <div v-if="isNoShop">
      <div class="flexAndCenter keywordContainer">
        <div class="flexAndCenter">
          <div class="keyword u-line-3 fontSize14 color333">{{ $fanyi("关键词") }}：{{
              $route.query.CN ? $route.query.keyword : $parent.keyWord
            }}
          </div>
          <div class="searchErrorSubmitContainer" @click="$parent.dialogVisible = true">
            <div>?</div>
            <div>{{ $fanyi("不是我想要查找的分类") }}</div>
          </div>
        </div>
      </div>
      <nav class="newStrSearch">
        <div :style="keywordSNQueryList.length>0&&shop_type==1688?'':'border-bottom:none'"
             class="strSearchItemContainer">
          <div class="label">{{ $fanyi("类别") }}：</div>
          <div class="valueContainer">
            <div :class="shop_type==1688?'colorB4272B':''" @click="$parent.updateShopType('1688')">1688</div>
            <div :class="shop_type=='taobao'?'colorB4272B':''" @click="$parent.updateShopType('taobao')">Taobao & Tmall
            </div>
          </div>
        </div>
        <div v-if="shop_type==1688">
          <div v-for="(item,index) in keywordSNQueryList" :key="index"
               :style="index!=keywordSNQueryList.length-1?'':'border-bottom:none'"
               class="strSearchItemContainer">
            <div class="label">{{ item.translateName }}：</div>
            <div class="valueContainer">
              <div v-for="(childrenItem,childrenIndex) in item.children" :key="childrenIndex"
                   :class="item.active==childrenIndex?'active':''"
                   @click="setSnId(item,childrenIndex)">
                {{ childrenItem.translateName }}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <nav class="newStrSearch">
        <div v-if="shop_type==1688" class="strSearchItemContainer">
          <div class="strictSelection"
               @click="lists = [];krOppChecked=false;jpOppChecked=!jpOppChecked;page = 1; getData();add();">
            <input v-model="jpOppChecked" type="checkbox"
                   @change.stop="lists = [];krOppChecked=false;page = 1; getData();add();"/>1688日本市場人気商品
            <img alt="" src="../../../assets/1688/hot.gif">
            <el-popover placement="bottom" trigger="hover" width="260">
              <div>
                1688の日本IPや日本のお客様の購入、閲覧などのデータを基づいて、売上やスコアのいいtop商品をピックアウトした商品です。
              </div>
              <div slot="reference" class="questionMark">?</div>
            </el-popover>
          </div>
          <div class="strictSelection"
               @click="lists = [];jpOppChecked=false;krOppChecked=!krOppChecked;page = 1; getData();add();">
            <input v-model="krOppChecked" type="checkbox"
                   @change.stop="lists = [];jpOppChecked=false;page = 1; getData();add();"/>
            1688韓国市場人気商品
            <img alt="" src="../../../assets/1688/hot.gif">
          </div>
          <div class="strictSelection"
               @click="lists = [];selectiveSupplyChecked=!selectiveSupplyChecked;page = 1; getData();">
            <input v-model="selectiveSupplyChecked" type="checkbox" @change.stop="lists = []; page = 1; getData();"/>
            1688厳選
          </div>
          <div class="strictSelection"
               @click="certifiedFactoryChecked=!certifiedFactoryChecked;lists = []; page = 1; getData();">
            <input v-model="certifiedFactoryChecked" type="checkbox"
                   @change.stop="lists = []; page = 1; getData();"/>
            厳選工場
          </div>
          <div class="strictSelection">
            <div style="margin-right: 6px">店舗評点</div>
            <el-select v-model="storeRatingValue" placeholder="全て" size="mini"
                       style="width: 120px;" @change="lists = []; page = 1; getData();">
              <el-option
                  v-for="item in $parent.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="strictSelection"
               @click="shipInTodayChecked=!shipInTodayChecked;lists = []; page = 1; getData();">
            <input v-model="shipInTodayChecked" type="checkbox" @change.stop="lists = []; page = 1; getData();"/>
            当日出荷
          </div>
          <div class="strictSelection"
               @click="shipln24HoursChecked=!shipln24HoursChecked;lists = []; page = 1; getData();">
            <input v-model="shipln24HoursChecked" type="checkbox" @change.stop="lists = []; page = 1; getData();"/>
            24時間以内出荷
          </div>
        </div>
        <div v-if="shop_type==1688" class="strSearchItemContainer">
          <div class="strictSelection"
               @click="shipln48HoursChecked=!shipln48HoursChecked;lists = []; page = 1; getData();">
            <input v-model="shipln48HoursChecked" type="checkbox"
                   @change.stop="lists = []; page = 1; getData();"/>
            48時間以内出荷
          </div>
          <div class="strictSelection"
               @click="noReason7DReturnChecked=!noReason7DReturnChecked;lists = []; page = 1; getData();">
            <input v-model="noReason7DReturnChecked" type="checkbox"
                   @change.stop="lists = []; page = 1; getData();"/>
            7日間以内無条件返品・交換
          </div>
          <div class="strictSelection"
               @click="isOnePsaleChecked=!isOnePsaleChecked;lists = []; page = 1; getData();">
            <input v-model="isOnePsaleChecked" type="checkbox"
                   @change.stop="lists = []; page = 1; getData();"/>
            1点から仕入れ可能
          </div>
        </div>
        <div class="strSearchItemContainer" style="border-bottom: none">
          <div :class="bottomFiltrateIndex==0?'selectFiltrateContainer':'filtrateContainer'"
               @click="updateBottomFiltrateIndex(0)">総合ランキング
          </div>
          <div v-if="shop_type==1688"
               :class="bottomFiltrateIndex==1?'selectFiltrateContainer':'filtrateContainer'"
               @click="updateBottomFiltrateIndex(1)">
            <div>販売数</div>
            <img :src="monthlySalesImg" alt="" style="margin-top: 4px">
          </div>
          <div v-if="shop_type==1688"
               :class="bottomFiltrateIndex==2?'selectFiltrateContainer':'filtrateContainer'"
               @click="updateBottomFiltrateIndex(2)">
            <div>リピート率</div>
            <img :src="repurchaseRateImg" alt="" style="margin-top: 4px">
          </div>
          <div :class="bottomFiltrateIndex==3||bottomFiltrateIndex==4?'selectFiltrateContainer':'filtrateContainer'"
               @click="newUpdateBottomFiltrateIndex">
            <div>価格</div>
            <div class="iconContainer">
              <img :src="priceAscendingOrderImg" alt="" style="margin-bottom: 2px">
              <img :src="priceDescendingOrderImg" alt="">
            </div>
          </div>
          <div v-if="shop_type==1688" class="priceContainer">
            <div class="flexAndCenter">
              <div class="label">￥</div>
              <el-input v-model="minPrice" placeholder="最安価格" type="number" @keydown.native="channelInputLimit"/>
            </div>
            <div class="halvingLine">-</div>
            <div class="flexAndCenter">
              <div class="label">￥</div>
              <el-input v-model="maxPrice" placeholder="最高価格" type="number" @keydown.native="channelInputLimit"/>
            </div>
            <div class="btn" @click="lists = [];page = 1; getData();">確認</div>
          </div>
        </div>
      </nav>
      <div class="newTechnicalServiceSupport">
        こちらの検索は、1688より一部技術、情報サポートを受けて提供させて頂いております。
      </div>
      <!-- 没有找到商品 -->
      <div v-if="goodsListIsNull && lists.length == 0" class="goodsListIsNull">
        <div class="message">
          <span>大変申し訳ございません、</span>”{{
            $route.query.CN ? $route.query.keyword : $parent.keyWord
          }}”{{ $fanyi("没有找到商品，请换一个关键词试试") }}
        </div>
      </div>
      <!-- 搜索结果块状 -->
      <div v-show="!goodsListIsNull || lists.length > 0" v-loading="$parent.loading"
           style="width: 1400px;margin: 0 auto">
        <div class="SearchConterCrosswise">
          <div v-for="(item, index) in lists" :key="index"
               :data-aplus-report="item.traceInfo"
               :style="item.hoverStatus === true ? 'border: 1px solid #B4272B;' : ''"
               class="SearchConterCrosswiseCon" data-tracker="offer"
               @mouseleave="goodsMouseleave(index)">
            <!-- 放图片的div -->
            <div class="SearchConterCrosswiseImg" @click="toCommodityDetails(item)"
                 @mouseenter="goodsMouseenter(index)">
              <el-image :src="item.imgUrl" class="biggoodsImg">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
                <div slot="placeholder">
                  <i class="el-icon-loading"></i>
                </div>
              </el-image>
            </div>
            <!-- 显示其他信息的div -->
            <div v-show="item.hoverStatus === false"
                 class="rests">
              <p :title="item.titleT" class="goodsTitle">
                <span v-if="item.shopType==1688&&item.isJxhy==false" class="type">1688</span>
                <span v-if="item.shopType==1688&&item.isJxhy==true" class="jxhyType">1688厳選</span>
                {{ item.titleT }}
              </p>
              <p>
              <span>
                {{ Number(item.goodsPrice).toFixed(2) }}{{
                  $fanyi("元")
                }}（{{ (Number(item.goodsPrice) * exchangeRate).toFixed(0) }}{{ $fanyi("円") }}）
              </span>
                <span v-if="item.shopType==1688" style="color: #999999">販売数<span
                    style="color: #B4272D!important;">{{
                    item.monthSold
                  }}</span>件</span>
              </p>
              <div style="display: flex;flex-wrap: wrap">
                <div v-if="item.shopType==1688" style="display: flex;flex-wrap: wrap;margin-bottom: 6px">
                  <div v-for="(newItem,newIndex) in item.sellerIdentities" :key="newIndex">
                    <div v-if="newItem=='super_factory'" :style="newIndex==0?'margin-right: 6px':''"
                         class="powerfulMerchantsContainer">
                      <img alt="" src="../../../assets/1688/powerfulMerchantsIcon.png">
                      <span>スーパーメーカー</span>
                    </div>
                    <div v-if="newItem=='powerful_merchants'" :style="newIndex==0?'margin-right: 6px':''"
                         class="superFactoryContainer">
                      <img alt="" src="../../../assets/1688/superFactoryIcon.png">
                      <span>実力商家</span>
                    </div>
                  </div>
                </div>
                <div v-if="item.shopType==1688" class="realignmentContainer">
                  <div v-if="certifiedFactoryChecked==true">1688厳選工場</div>
                  <div v-if="jpOppChecked===true||krOppChecked===true">{{
                      jpOppChecked == true ? '日本売れ筋' : '韓国売れ筋'
                    }}
                  </div>
                </div>
                <div v-if="item.shopType==1688" class="repurchaseRate">リピート率
                  <span>{{ item.repurchaseRate }}</span>％
                </div>
              </div>
            </div>
            <div v-show="item.hoverStatus === true"
                 class="shopInfo">
              <div v-show="item.hoverStatus === true" class="findSimilarityContainer"
                   @click.stop="$parent.imgSearchGoods(item.imgUrl)">{{ $fanyi("查找相似") }}
              </div>
              <div class="shopInfoHeader" style="justify-content: space-between">
                <div class="goodCompare" @click.stop="addCommercialMatch(item)">
                  <img alt="" src="../../../assets/1688/goodCompare.png">
                  比較
                </div>
                <div class="goodsFavorite" @click.stop="favoriteGoodsAdd(item)">
                  <img v-if="item.goodsFavoriteStatus" alt="" src="../../../assets/SearchResultsPage/Collected.png">
                  <img v-else alt="" src="../../../assets/SearchResultsPage/collection.png">
                  {{ item.goodsFavoriteStatus ? $fanyi("取消收藏") : $fanyi("收藏") }}
                </div>
              </div>
              <div v-if="item.shopType==1688"
                   :style="item.shopInfo==undefined||$fun.isArray(item.shopInfo)||(!$fun.isArray(item.shopInfo)&&item.shopInfo.shopName==undefined&&item.shopInfo.year==undefined)?'border-bottom:none':''"
                   class="shopDataContainer">
                <div class="newRateContainer">
                  <div style="margin-right: 7px">総合評点</div>
                  <el-rate
                      v-model="item.tradeScore"
                      disabled
                      text-color="#ff9900">
                  </el-rate>
                </div>
                <div class="shopDataBottom">
                  <div class="repurchaseRate" style="margin-right:6px">リピート率
                    <span>{{ item.repurchaseRate }}</span>％
                  </div>
                  <div class="repurchaseRate" style="width: 128px;">販売数<span>{{
                      item.monthSold
                    }}</span>件
                  </div>
                </div>
              </div>
              <div
                  v-if="item.shopInfo!=undefined&&!$fun.isArray(item.shopInfo)&&item.shopType==1688&&item.shopInfo.shopName!=undefined&&item.shopInfo.year!=undefined"
                  class="shopNameContainer">
                <div v-if="item.sellerIdentities!=undefined&&item.sellerIdentities.indexOf('tp_member')==-1"
                     class="yearContainer">
                  <div><img alt="" src="../../../assets/1688/shopIcon.png"></div>
                  <div>{{ item.shopInfo.year }}年</div>
                </div>
                <div v-if="item.sellerIdentities!=undefined&&item.sellerIdentities.indexOf('tp_member')!=-1"
                     class="tpMemberContainer">
                  <div><img alt="" src="../../../assets/1688/tp_member_icon.png"></div>
                  <div>{{ item.shopInfo.year }}年</div>
                </div>
                <div :title="item.shopInfo.shopName" class="shopName cursorPointer"
                     @click="shopPageJump(item)">{{
                    item.shopInfo.shopName
                  }}
                </div>
              </div>
            </div>
          </div>
          <div v-show="$parent.keywordLoading" class="keywordLoadingContainer">
            <div class="flexAndCenter">
              <img alt="" src="@/assets/loading.gif">
              <div>読み込み中</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索结果块状 -->
    <div v-show="isNoShop==false" v-loading="shopLoading"
         style="width: 1400px;margin: 0 auto 20px;height: 200px;background: #fff;">

    </div>
  </div>
</template>

<script>
import loginDialog from "@/components/public/loginDialog.vue";

export default {
  components: {loginDialog},
  data() {
    return {
      minPrice: '',
      maxPrice: '',
      shop_type: this.$parent.shop_type,
      snId: '',
      jpOppChecked: false,
      krOppChecked: false,
      sort: {},
      page: 1,
      goodsListIsNull: null,
      shipInTodayChecked: false,
      selectiveSupplyChecked: false,
      certifiedFactoryChecked: false,
      shipln24HoursChecked: false,
      storeRatingValue: '',
      shipln48HoursChecked: false,
      noReason7DReturnChecked: false,
      lists: [],
      keywordSNQueryList: [],
      isOnePsaleChecked: false,
      isNoShop: null,
      shopLoading: false,
      bottomFiltrateIndex: null,
      monthlySalesImg: require('../../../assets/1688/bottomNoSelect.png'),
      repurchaseRateImg: require('../../../assets/1688/bottomNoSelect.png'),
      priceAscendingOrderImg: require('../../../assets/1688/topNoSelect.png'),
      priceDescendingOrderImg: require('../../../assets/1688/bottomNoSelect.png'),
    }
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  created() {
    this.$store.commit('getsearchInfo', this.$parent.keyWord,);
  },
  methods: {
    // 获取列表数据
    getData() {
      if (this.$route.query.keyword.includes('spm=')) {
        this.isNoShop = false;
        this.shopLoading = true;
        this.$forceUpdate();
        this.$api.getStoreShopId({
          keywords: this.$parent.keyWord
        }).then((result) => {
          this.shopLoading = false;
          if (result.code != 0) {
            this.isNoShop = true
            this.keywordSearchProduct();
            return
          }
          this.lists.push(result.data);
          if (this.$fun.isOfficialWeb()) {
            __bl.sum('通过搜索栏访问店铺详情');
          }
          let super_factory_status = undefined;
          let powerful_merchants_status = undefined;
          if (result.data.offerIdentities != undefined) {
            result.data.offerIdentities.indexOf('super_factory') != -1 ? super_factory_status = true : undefined
            result.data.offerIdentities.indexOf('powerful_merchants') != -1 ? powerful_merchants_status = true : undefined
          }
          this.$fun.routerToPage(`/shopGoods?shopId=${result.data.shop_id}&wangName=${result.data.wangName}&shopName=${result.data.shopName}&shopType=1688${super_factory_status == true ? '&super=true' : ''}${powerful_merchants_status == true ? '&powerful=true' : ''}`)
        })
      } else {
        this.isNoShop = true
        this.keywordSearchProduct();
      }
    },
    keywordSearchProduct() {
      let filterStr = '';
      let arr = [];
      if (this.$parent.loading === false) {
        this.$parent.keywordLoading = true;
      }
      if (this.lists.length === 0) {
        this.$parent.loading = true;
      }
      let datas = {
        priceStart: this.shop_type == 1688 ? this.minPrice : undefined,
        priceEnd: this.shop_type == 1688 ? this.maxPrice : undefined,
        page: this.page,
        shop_type: this.shop_type,
        keywords: this.$parent.keyWord,
        pageSize: this.shop_type == 1688 ? 50 : 20,
        snId: this.shop_type == 1688 ? this.snId : undefined,
        outMemberId: localStorage.getItem('japan_user_token') != null ? this.$fun.setUserID() : undefined
      }
      if (this.shop_type == 1688) {
        if (this.shipInTodayChecked) {
          arr.push('shipInToday')
        }
        if (this.selectiveSupplyChecked) {
          arr.push('jxhy')
        }
        if (this.certifiedFactoryChecked) {
          arr.push('certifiedFactory')
        }
        if (this.storeRatingValue != '') {
          arr.push(this.storeRatingValue)
        }
        if (this.shipln24HoursChecked) {
          arr.push('shipln24Hours')
        }
        if (this.shipln48HoursChecked) {
          arr.push('shipln48Hours')
        }
        if (this.noReason7DReturnChecked) {
          arr.push('noReason7DReturn')
        }
        if (this.isOnePsaleChecked) {
          arr.push('isOnePsale')
        }
        if (this.$route.query.regionOpp != undefined) {
          datas.regionOpp = this.$route.query.regionOpp;
        } else {
          datas.regionOpp = this.jpOppChecked == true ? 'jpOpp' : this.krOppChecked == true ? 'krOpp' : undefined
        }
        if (arr.length != 0) {
          filterStr = arr.join(',')
          datas.filter = filterStr
        }
      }
      if (typeof this.sort != 'string' && JSON.stringify(this.sort) != '{}') {
        datas.sort = JSON.stringify(this.sort);
      } else if (typeof this.sort == 'string') {
        datas.sort = this.sort
      }
      this.$api.EuropeKeywordSearchProduct(datas).then((result) => {
        this.$parent.keywordLoading = false;
        this.$parent.loading = false;
        if (result.code != 0) {
          this.goodsListIsNull = true;
          this.$message.error(this.$fanyi(result.data))
          return
        }
        if (result.data.data_type === 'goodsList') {
          if ((this.shop_type != 1688 && Number(result.data.result.total) < 20) || (this.shop_type == 1688 && Number(result.data.result.total) < 50)) {
            this.$parent.keywordLoading = undefined;
          }
          if (result.data.result.length == 0) {
            this.goodsListIsNull = true;
            if (this.lists.length == 0) {
              this.total = 0;
              this.lists = [];
            }
          } else {
            result.data.result.result.forEach((item) => {
              item.hoverStatus = false;
              if (item.tradeScore != undefined) {
                item.tradeScore = Number(item.tradeScore)
              }
              if (item.whiteImage != undefined && item.whiteImage != '') {
                item.imgUrl = item.whiteImage
              }
              if (item.shopInfo != undefined && !this.$fun.isArray(item.shopInfo) && item.shopInfo.shopTime != undefined) {
                if (item.shopInfo.shopTime.length != 19) {
                  item.shopInfo.year = 0
                } else {
                  let year = item.shopInfo.shopTime.substr(0, 4);
                  var date = new Date();
                  item.shopInfo.year = Number(date.getFullYear()) - Number(year);
                }
              }
              this.lists.push(item)
            })
            this.total = Number(result.data.result.total);
            this.$forceUpdate();
            this.favoriteGoodsCheckIn();
            this.goodsListIsNull = false;
          }
        } else if (result.data.data_type === 'shopID') {//店铺链接跳转
          if (this.$fun.isOfficialWeb()) {
            __bl.sum('通过搜索栏访问店铺详情');
          }
          let super_factory_status = undefined;
          let powerful_merchants_status = undefined;
          if (result.data.result.offerIdentities != undefined) {
            result.data.result.offerIdentities.indexOf('super_factory') != -1 ? super_factory_status = true : undefined
            result.data.result.offerIdentities.indexOf('powerful_merchants') != -1 ? powerful_merchants_status = true : undefined
          }
          this.$fun.routerToPage(`/shopGoods?shopId=${result.data.result.shop_id}&wangName=${result.data.result.wangName}&shopName=${result.data.result.shopName}&shopType=1688${super_factory_status == true ? '&super=true' : ''}${powerful_merchants_status == true ? '&powerful=true' : ''}`)
        } else {
          //商品链接跳转
          this.$fun.routerToPage("/ProductDetails?goods_id=" + result.data.result.goodsId + "&fromPlatform=" + result.data.result.fromPlatform + '&recommendedGoods=false&source=link');
        }
      })
    },
    shopPageJump(item) {
      let super_factory_status = undefined;
      let powerful_merchants_status = undefined;
      if (item.sellerIdentities != undefined) {
        item.sellerIdentities.indexOf('super_factory') != -1 ? super_factory_status = true : undefined
        item.sellerIdentities.indexOf('powerful_merchants') != -1 ? powerful_merchants_status = true : undefined
      }
      if ((item.shopInfo.shopName != '' && item.shopInfo.shopName != undefined) && ((item.shopInfo.shop_id != '' && item.shopInfo.shop_id != undefined) || (item.shopInfo.shopId != '' && item.shopInfo.shopId != undefined)) && (item.shopInfo.wangwang != '' && item.shopInfo.wangwang != undefined)) {
        this.$fun.routerToPage(`/shopGoods?shopId=${item.shopInfo.shop_id == undefined ? item.shopId : item.shopInfo.shop_id}&wangName=${item.shopInfo.wangwang}&shopName=${item.shopInfo.shopName}&shopType=1688${super_factory_status == true ? '&super=true' : ''}${powerful_merchants_status == true ? '&powerful=true' : ''}`)
      }
    },
    //追加比较
    addCommercialMatch(val) {
      let commercialMatchList = []
      if (localStorage.getItem("commercialMatchList") != null) {
        commercialMatchList = JSON.parse(localStorage.getItem('commercialMatchList'));
      }
      if (commercialMatchList.length < 30) {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('关键词搜索增加比较商品数量统计');
        }
        let flag = commercialMatchList.findIndex(item => item.title == val.titleT)
        if (flag != -1) {
          this.$message.error('該当商品は追加済みです、他の商品をお選び下さい。')
        } else {
          commercialMatchList.push({
            imgUrl: val.imgUrl,
            title: val.titleT,
            titleC: val.titleC,
            price: val.goodsPrice,
            shopType: val.shopType,
            goodsId: val.goodsId,
            monthSold: val.monthSold == undefined ? '' : Number(val.monthSold),
            repurchaseRate: val.repurchaseRate == undefined ? '' : Number(val.repurchaseRate),
            shopName: val.shopInfo == undefined || this.$fun.isArray(val.shopInfo) == true ? '' : val.shopInfo.shopName,
            link: `https://www.rakumart.com/ProductDetails?goods_id=${val.goodsId}&fromPlatform=${val.shopType}`
          })
          localStorage.setItem("commercialMatchList", JSON.stringify(commercialMatchList));
          this.$store.commit('setCommercialMatchGoodsCount', commercialMatchList.length)
          this.$message.success('操作が成功しました')
        }
      } else {
        this.$message.error('最大30個の商品を追加できます')
      }
    },
    //多语言导航选择
    setSnId(item, childrenIndex) {
      if ((item.active != null && item.active != childrenIndex) || item.active == null) {
        item.active = childrenIndex;
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('选择多语言导航次数');
        }
      } else if (item.active != null && item.active == childrenIndex) {
        item.active = null;
      }
      let arr = [];
      this.keywordSNQueryList.forEach((item) => {
        if (item.active != null) {
          arr.push(item.children[item.active].id)
        }
      })
      this.snId = arr.join(';');
      this.lists = [];
      this.page = 1;
      this.getData();
    },
    add() {
      if (this.$fun.isOfficialWeb() && (this.krOppChecked == true || this.jpOppChecked == true)) {
        __bl.sum('关键词搜索与图片搜索点击爆品筛选数量统计');
      }
    },
    // 查看店铺是否已收藏
    favoriteGoodsCheckIn() {
      let ids = [];
      this.lists.forEach((goodsItem) => {
        goodsItem.goodsFavoriteStatus = false;
        ids.push(goodsItem.goodsId);
      });
      this.$api.favoriteGoodsCheckIn({
        goods_id: ids,
        shop_type: this.shop_type
      })
      .then((res) => {
        if (res.code != 0) return false;
        res.data.forEach((resItem, resIndex) => {
          this.lists[resIndex].goodsFavoriteStatus = resItem.inFavorite;
        });
      });
    },
    //跳转到商品详情
    toCommodityDetails(val) {
      let type = '';
      if (this.$route.query.link != undefined) {
        type = 'link';
      } else if (this.$route.query.classifyKeyword != undefined) {
        type = 'classify'
      } else {
        if (this.$route.query.type != undefined && this.$route.query.type == 'imgSearch') {
          type = 'img'
          if (this.$fun.isOfficialWeb()) {
            __bl.sum('通过图搜进入商品详情页统计');
          }
        } else {
          type = 'keyword'
          if (this.$fun.isOfficialWeb()) {
            __bl.sum('通过关键词搜索进入商品详情页统计');
          }
        }
      }
      if (localStorage.getItem('japan_user_token') == null) {
        this.$refs.dialog.visible = true;
        return
      }
      if (this.jpOppChecked == true || this.krOppChecked == true) {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('通过关键词与图搜访问爆品商品统计');
        }
        this.$fun.newToCommodityDetails(val.goodsId, val.shopType, true, type)
      } else {
        if (type != '') {
          this.$fun.newToCommodityDetails(val.goodsId, val.shopType, false, type)
        } else {
          this.$fun.toCommodityDetails(val.goodsId, val.shopType)
        }
      }
    },
    channelInputLimit(e) {
      let key = e.key;
      key = key.replace(/１/g, '1')
      key = key.replace(/２/g, '2')
      key = key.replace(/３/g, '3')
      key = key.replace(/４/g, '4')
      key = key.replace(/５/g, '5')
      key = key.replace(/６/g, '6')
      key = key.replace(/７/g, '7')
      key = key.replace(/８/g, '8')
      key = key.replace(/９/g, '9')
      key = key.replace(/０/g, '0')
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" || key === "." || key === "。" || key === "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    //商品详细鼠标移入
    goodsMouseenter(index) {
      this.lists[index].hoverStatus = true;
      this.$forceUpdate();
    },
    //商品详细鼠标移出
    goodsMouseleave(index) {
      this.lists[index].hoverStatus = false;
      this.$forceUpdate();
    },
    //筛选
    updateBottomFiltrateIndex(val) {
      this.bottomFiltrateIndex = val;
      switch (val) {
        case 0:
          this.monthlySalesImg = require('../../../assets/1688/bottomNoSelect.png');
          this.repurchaseRateImg = require('../../../assets/1688/bottomNoSelect.png');
          this.priceAscendingOrderImg = require('../../../assets/1688/topNoSelect.png');
          this.priceDescendingOrderImg = require('../../../assets/1688/bottomNoSelect.png');
          this.sort = {}
          break;
        case 1:
          this.monthlySalesImg = require('../../../assets/1688/bottomSelect.png');
          this.repurchaseRateImg = require('../../../assets/1688/bottomNoSelect.png');
          this.priceAscendingOrderImg = require('../../../assets/1688/topNoSelect.png');
          this.priceDescendingOrderImg = require('../../../assets/1688/bottomNoSelect.png');
          this.sort = {
            monthSold: 'desc'
          }
          break;
        case 2:
          this.monthlySalesImg = require('../../../assets/1688/bottomNoSelect.png');
          this.repurchaseRateImg = require('../../../assets/1688/bottomSelect.png');
          this.priceAscendingOrderImg = require('../../../assets/1688/topNoSelect.png');
          this.priceDescendingOrderImg = require('../../../assets/1688/bottomNoSelect.png');
          this.sort = {
            rePurchaseRate: 'desc'
          }
          break;
        case 3:
          this.monthlySalesImg = require('../../../assets/1688/bottomNoSelect.png');
          this.repurchaseRateImg = require('../../../assets/1688/bottomNoSelect.png');
          this.priceAscendingOrderImg = require('../../../assets/1688/topSelect.png');
          this.priceDescendingOrderImg = require('../../../assets/1688/bottomNoSelect.png');
          if (this.shop_type == 1688) {
            this.sort = {
              price: 'asc'
            }
          } else {
            this.sort = 'PRICE_ASC'
          }
          break;
        case 4:
          this.monthlySalesImg = require('../../../assets/1688/bottomNoSelect.png');
          this.repurchaseRateImg = require('../../../assets/1688/bottomNoSelect.png');
          this.priceAscendingOrderImg = require('../../../assets/1688/topNoSelect.png');
          this.priceDescendingOrderImg = require('../../../assets/1688/bottomSelect.png');
          if (this.shop_type == 1688) {
            this.sort = {
              price: 'desc'
            }
          } else {
            this.sort = 'PRICE_DESC'
          }
          break;
      }
      this.lists = [];
      this.page = 1;
      this.getData();
    },
    //筛选
    newUpdateBottomFiltrateIndex() {
      if (this.bottomFiltrateIndex != 3 && this.bottomFiltrateIndex != 4) {
        this.updateBottomFiltrateIndex(3);
      } else {
        if (this.bottomFiltrateIndex != 3) {
          this.updateBottomFiltrateIndex(3);
        } else {
          this.updateBottomFiltrateIndex(4);
        }
      }
    },
    // 添加收藏商品
    favoriteGoodsAdd(item) {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (!this.$store.state.userInfo && japan_user_token == null) {
        this.$refs.dialog.visible = true;
        return
      }
      let datas = {
        shop_type: item.shopType,
        goods_id: item.goodsId,
        title: item.titleC,
        image_url: item.imgUrl,
        price: item.goodsPrice,
      };
      if (!item.goodsFavoriteStatus) {
        this.$api.favoriteGoodsAdd(datas).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          item.goodsFavoriteStatus = !item.goodsFavoriteStatus;
          this.$message.success(this.$fanyi(res.msg))
          this.$forceUpdate();
        });
      } else {
        let arr = [];
        arr.push({
          shop_type: item.shopType,
          goods_id: item.goodsId
        })
        this.$api.favoriteGoodsDelete({delete: arr}).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg))
          item.goodsFavoriteStatus = !item.goodsFavoriteStatus;
          this.$forceUpdate();
        });
      }
    },
    //获取多语言导航
    getKeywordSNQuery(val) {
      this.$api.keywordSNQuery({
        keyword: val
      }).then((result) => {
        if (result.code != 0) return
        result.data.forEach((item) => {
          item.active = null;
        })
        this.keywordSNQueryList = result.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../css/CommoditySearch.scss";

.newStrSearch {
  background: #fff;
  width: 1400px;
  margin: 20px auto 14px;
  border: 1px solid #DFDFDF;
  padding: 0 20px;

  .strSearchItemContainer {
    min-height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #DFDFDF;

    .label {
      font-size: 14px;
      color: #999999;
      width: 118px;
    }

    .valueContainer {
      display: flex;
      flex-wrap: wrap;

      div {
        font-size: 14px;
        color: #333333;
        margin-right: 40px;
        cursor: pointer;
      }

      .active {
        padding: 2px 5px;
        border-radius: 4px;
        background: linear-gradient(90deg, #FF7E3E, #FF5010, #FE4B0F);
        color: #FFFFFF;
      }
    }

    .strictSelection {
      color: #000000;
      display: flex;
      align-items: center;
      margin-right: 40px;
      cursor: pointer;
      font-size: 14px;

      input {
        margin-right: 4px;
      }

      img {
        margin-top: -20px;
      }
    }

    .filtrateContainer, .selectFiltrateContainer {
      display: flex;
      align-items: center;
      margin-right: 60px;
      cursor: pointer;
      font-size: 14px;

      div {
        margin-right: 5px;
      }

      .iconContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2px;
      }
    }

    .selectFiltrateContainer {
      font-weight: bold;
      color: #B4272B;
    }

    .priceContainer {
      display: flex;
      align-items: center;

      .flexAndCenter {
        width: 100px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #999999;
        border-radius: 6px;
        padding-left: 12px;

        .label {
          color: #000000;
          font-weight: 400 !important;
          font-size: 14px !important;
          width: auto !important;
        }

        /deep/ .el-input__inner {
          height: 28px;
          border: none;
          padding: 0;
        }
      }

      .halvingLine {
        margin: -6px 6px 0;
        color: #999999;
        font-size: 30px;
      }

      .btn {
        margin-left: 10px;
        width: 56px;
        height: 30px;
        background: #B4272B;
        border-radius: 6px;
        line-height: 30px;
        cursor: pointer;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #fff;
      }
    }
  }
}

.newTechnicalServiceSupport {
  font-size: 14px;
  color: #999999;
  margin: 20px auto;
  width: 1400px;
}

.powerfulMerchantsContainer {
  background: #F8F6FF !important;
  width: 131px !important;

  span {
    color: #3700E1 !important;
  }
}

.jxhyType {
  padding: 2px 4px 2px 6px;
  background: linear-gradient(90deg, #E4AE42, #C38D22);
  border-radius: 2px;
  font-size: 14px;
  color: #FFFFFF;
}

.superFactoryContainer, .powerfulMerchantsContainer {
  width: 84px;
  height: 24px;
  background: #FFF5F5;
  border-radius: 4px;
  padding: 0 6px;
  display: flex;
  align-items: center;

  img {
    width: 18px;
    height: 17px;
    margin-right: 5px;
  }

  span {
    color: #F72A2B;
    font-size: 12px;
  }
}

.shopInfo {
  padding: 0 15px;
  position: absolute;
  left: 0;
  top: 52%;
  background: #fff;
  box-sizing: border-box;
  width: 261px;
  animation: slideUp 1s ease forwards;

  .findSimilarityContainer {
    position: absolute;
    left: 0;
    top: -31px;
    height: 32px;
    width: 261px;
    background: #E0787E;
    cursor: pointer;
    color: #fff;
    text-align: center;
    line-height: 32px;
    font-size: 12px;
  }

  .findSimilarityContainer:hover {
    background: #C40622;
  }

  .shopInfoHeader {
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    font-size: 14px;

    .goodsFavorite, .goodCompare {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 118px;
      cursor: pointer;
      font-size: 12px;
    }

    .goodCompare {
      border-right: 1px solid #DFDFDF;
    }

    img {
      width: 18px;
      height: 18px;
      margin-right: 7px;
    }
  }

  .shopDataContainer {
    padding: 20px 0 15px;
    border-top: 1px solid #DFDFDF;
    border-bottom: 1px solid #DFDFDF;
    cursor: default;

    .newRateContainer {
      display: flex;
      font-size: 16px;
      color: #999999;
      margin-bottom: 14px;
    }

    .shopDataBottom {
      display: flex;
      align-items: center;
    }
  }

  .shopNameContainer {
    padding: 15px 0;
    display: flex;
    align-items: center;

    .yearContainer, .tpMemberContainer {
      width: 62px;
      height: 24px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #F72A2B;
      display: flex;
      align-items: center;
      margin-right: 5px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div:first-child {
        width: 26px;
        height: 24px;
        background: #F72A2B;
        border-radius: 4px 0 0 4px;

        img {
          width: 18px;
          height: 17px;
        }
      }

      div:last-child {
        flex: 1;
        font-size: 12px;
        color: #F72A2B;
      }
    }

    .tpMemberContainer {
      border: 1px solid #FF6000 !important;

      div:first-child {
        background: #FF6000 !important;
      }

      div:last-child {
        color: #FF6000 !important;
      }
    }

    .shopName {
      width: 178px;
      font-size: 14px;
      color: #000000;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

/* 定义动画 */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>