<template>
  <div id="CommoditySearch">
    <HomePageTop ref="head" @shopTypeChange="updateShopType"></HomePageTop>
    <!-- 关键词搜索 -->
    <div style="margin:24px auto 23px;width: 1400px">
      <img alt="" src="../../assets/1688/longPic.png">
    </div>
    <component :is="componentName" ref="component"/>
    <!--        tag编辑-->
    <div class="submitErrorContainer">
      <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="$fanyi('错误报告')"
                 :visible.sync="dialogVisible" center width="385px">
        <el-radio-group v-model="radio">
          <el-radio :label="2">{{ $fanyi('当前搜索结果不是您想查找的商品') }}</el-radio>
          <el-radio :label="1">{{ $fanyi('搜索不出结果') }}</el-radio>
          <el-radio :label="3">{{ $fanyi('其他') }}</el-radio>
        </el-radio-group>
        <el-input v-model="textarea" resize="none" type="textarea"></el-input>
        <div class="flexAndCenter">如果您需要帮助，请 <span class="contactUs" @click="lianxi">联系我们</span> 。</div>
        <div class="btnList">
          <div @click="dialogVisible = false">{{ $fanyi('取消') }}</div>
          <div @click="submitFeedback">{{ $fanyi('提交') }}</div>
        </div>
      </el-dialog>
    </div>
    <Foot></Foot>
  </div>
</template>
<script>
import Foot from "../../components/foot/Foot.vue";
import HomePageTop from "../../components/head/HomePageTop.vue";
import keywordSearch from "@/views/order/components/keywordSearch.vue";
import imageSearch from "@/views/order/components/imageSearch.vue";

export default {
  data() {
    return {
      form: {}, //搜索条件
      total: 0,
      keyWord: "",
      radio: 1,
      textarea: '',
      storeRatingValue: '',
      options: [
        {
          value: 'totalEpScoreLv1',
          label: '5つ星'
        },
        {
          value: 'totalEpScoreLv2',
          label: '4.5星~5.0星'
        },
        {
          value: 'totalEpScoreLv3',
          label: '4.0星~4.5星'
        },
      ],
      shipInTodayChecked: false,
      shipln24HoursChecked: false,
      shipln48HoursChecked: false,
      hideIdenticalChecked: false,
      highlightDifferenceChecked: false,
      noReason7DReturnChecked: false,
      noTaoBaoSelectUrl: require('../../assets/1688/search1688Select.png'),//1688
      taoBaoSelectUrl: require('../../assets/1688/searchTaoBaoNoSelect.png'),//taobao和天猫
      selectColumnNoSelectUrl: require('../../assets/newImg/selectColumnNoSelect.png'),
      selectRowSelectUrl: require('../../assets/newImg/selectRowSelect.png'),
      monthlySalesImg: require('../../assets/1688/bottomNoSelect.png'),
      repurchaseRateImg: require('../../assets/1688/bottomNoSelect.png'),
      priceAscendingOrderImg: require('../../assets/1688/topNoSelect.png'),
      priceDescendingOrderImg: require('../../assets/1688/bottomNoSelect.png'),
      bottomFiltrateIndex: null,
      certifiedFactoryChecked: false,
      selectiveSupplyChecked: false,
      jpOppChecked: false,
      krOppChecked: false,
      lists: [],
      shop_type: 1688,
      loading: true,
      minPrice: '',
      maxPrice: '',
      order_by: [],
      sort: {},
      priceSortText: '価格',
      commercialMatchDialogVisible: false,
      dialogVisible: false,
      keywordLoading: false,
      isImgSearch: false,
      componentName: 'keywordSearch',
      goodsListIsNull: false, //搜索结果是否有商品
    };
  },
  components: {
    HomePageTop,
    Foot,
    keywordSearch,
    imageSearch
  },
  created() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (this.$route.query.type) {
      this.isImgSearch = true;
    }
    this.initialized();
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  watch: {
    $route: {
      handler(route) {
        if (route.name == "CommoditySearch") {
          this.isImgSearch = this.$route.query.type == "imgSearch";
          this.initialized();
        }
      },
    },
  },
  methods: {
    async lianxi() {
      if (location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1) {
        if (this.$store.state.userInfo != null) {
          // if (this.$store.state.userInfo.chatwork != '') {
          //   var a = document.createElement("a");
          //   a.setAttribute("href", `https://www.chatwork.com/#!rid' + ${this.$store.state.userInfo.chatwork}`);
          //   a.setAttribute("target", '_blank')
          //   let clickEvent = document.createEvent("MouseEvents");
          //   clickEvent.initEvent("click", true, true);
          //   a.dispatchEvent(clickEvent);
          // } else {
          let user_token = localStorage.getItem('japan_user_token')
          this.loading = this.$loading({
            lock: true,
            spinner: "el-icon-loading fontSize50",
            background: "rgba(255, 255, 255, 0.7)",
          });
          await this.$api.userTimGroupCheck({user_id: this.$store.state.userInfo.operation_id}).then(() => {
            this.loading.close();
            var a = document.createElement("a");
            a.setAttribute("href", `https://rakuchat.rakumart.com/client_web/#/?id=${this.$store.state.userInfo.operation_id}&token=${user_token}`);
            a.setAttribute("target", '_blank')
            let clickEvent = document.createEvent("MouseEvents");
            clickEvent.initEvent("click", true, true);
            a.dispatchEvent(clickEvent);
          });
          //}
        } else {
          window.open('https://rakuchat.rakumart.com/visitor_web/#/')
        }
      }
    },
    //提交搜索意见反馈
    submitFeedback() {
      this.$api.searchGoodsFeedback({
        search_keywords: this.$route.query.type === "imgSearch" ? this.$route.query.imgUrl : this.$route.query.keyword,
        type: this.radio,
        remark: this.textarea,
      }).then((result) => {
        if (result.code != 0) {
          this.$message.error(result.msg)
          return
        }
        this.dialogVisible = false;
        this.$message.success(result.msg);
      })
    },
    //图片搜索
    imgSearchGoods(img) {
      this.$store.commit("getsearchImg", img);
      this.$fun.routerToPage("/CommoditySearch?type=imgSearch&imgUrl=" + img);
    },
    // 路由改变时的操作
    initialized() {
      if (this.$route.query.regionOpp == undefined && this.$route.query.classifyKeyword == undefined) {
        let index = this.$route.fullPath.indexOf('?');
        let keyWord = this.$route.fullPath.substr(index + 9, this.$route.fullPath.length);
        // 如果路由中带有CN就代表是点击首页的分类跳转过来的，需要读取路由中的中文关键词以进行精确搜索，否则获取路由中的普通关键词
        keyWord = decodeURIComponent(keyWord);
        if (!!this.$route.query.CN) {
          this.keyWord = this.$fun.fromCode(this.$route.query.CN);
        } else if (this.$route.query.keyword) {
          this.keyWord = keyWord;
        }
      } else {
        this.keyWord = this.$route.query.keyword
      }
      this.form = {
        keywords: this.keyWord,
        shop_type: this.shop_type,
      };
      if (this.$route.query.type == "imgSearch") {
        this.certifiedFactoryChecked = false;
        this.jpOppChecked = false;
        this.krOppChecked = false;
        this.componentName = 'imageSearch'
      } else {
        this.componentName = 'keywordSearch'
      }
      if (this.$route.query.gk != undefined) {
        localStorage.setItem("gkValue", this.$route.query.gk);
      }
      setTimeout(() => {
        this.$forceUpdate();
        this.updateShopType(this.$store.state.shopType);
        if (this.$route.query.type == "imgSearch") {
          this.$refs.component.fileList.push({
            url: this.$route.query.imgUrl,
            name: '图片1',
          })
        }
        if (this.$route.query.type != "imgSearch" && this.keyWord.indexOf('http') == -1 && this.$store.state.shopType == 1688) {
          this.$refs.component.getKeywordSNQuery(this.keyWord)
        }
        this.addKeyWord(this.keyWord);
      }, 1000)
    },
    //加入搜索历史
    addKeyWord(val) {
      if (val != '') {
        if (localStorage.getItem('keyWord') != null) {
          let keyWordList = JSON.parse(localStorage.getItem('keyWord'));
          if (keyWordList.indexOf(val) == -1) {
            if (keyWordList.length >= 10) {
              keyWordList.splice(0, 1);
              keyWordList.push(val);
              localStorage.setItem("keyWord", JSON.stringify(keyWordList));
            } else {
              keyWordList.push(val)
              localStorage.setItem("keyWord", JSON.stringify(keyWordList))
            }
          }
        } else {
          let keyWordList = [];
          keyWordList.push(val)
          localStorage.setItem("keyWord", JSON.stringify(keyWordList))
        }
      }
    },
    //更新商品搜索时的店铺类别
    updateShopType(val) {
      this.form.shop_type = val;
      this.shop_type = val;
      this.$refs.component.shop_type = val;
      this.$refs.component.page = 1;
      this.$refs.component.lists = [];
      if (val === '1688' || val === '1688日本市場人気商品' || val === '1688韓国市場人気商品') {
        this.$refs.component.shop_type = 1688;
      }
      this.$refs.component.getData();
    },
    getbottom() {
      // 返回滚动条垂直滚动距离
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      let numHeight = scrollTop + clientHeight;
      if (numHeight > scrollHeight - 600 && this.keywordLoading === false && this.goodsListIsNull == false) {
        this.keywordLoading = true
        this.fn()
      }
    },
    fn() {
      if (this.keywordLoading === true) {
        this.$refs.component.page++;
        this.$refs.component.getData();
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.getbottom);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getbottom);
  },
};
</script>
<style lang='scss' scoped>
@import "./css/CommoditySearch.scss";
</style>